:root {
	--page-color: black;
	--text-color: white;
}

html,
body {
	/* height: 100%;  */
	/* this disable scroll */
	overflow: hidden;  
}

a,
li {
	font-family: "Roboto Mono", monospace;
	font-weight: 400;
	color: var(--text-color);
	text-decoration: none;
	list-style: none;
}


@media (max-width: 576px) {
	.nav-menu {
		margin-top: 5em;
	}
}